.size-option {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid #E5E9F1;

  .size-option-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    p {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.size-option {
  background: white;

  .size-option-text {
    color: black;
  }
}

.size-option:hover {
  background: #EEF1F6;
}

.size-option.current {
  border: none;
  background: #EC3E26;

  .size-option-text {
    color: white;
  }
}

@media screen and (max-width: 767px) {
  .size-option {
    height: 44px;
    border-radius: 8px;
  }
}
