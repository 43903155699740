.navbar {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 2;
  width: 100%;
  background: white;
  transition: 0.2s;

  .navbar-content {
    margin: 0 auto;
    padding: 0 40px;

    // Styling for desktop navbar links
    .desktop-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70px;

      .sanctuary-logo {
        width: 125px;
        height: 100%;

        svg {
          width: 100%;
          height: 100%;
        }

      }

      .search-box {
        margin-left: 10px;
        width: 550px;
        height: 40px;
        background: #EEF1F6;
        border-radius: 11px;

        .search-box-content {
          display: flex;
          align-items: center;
          height: 100%;

          .search-box-icon {
            display: flex;
            padding: 0px 8px;

            svg {
              width: 14px;
              height: auto;

              path {
                stroke: #919BB1;
              }

              rect {
                fill: #919BB1;
              }
            }
          }

          .search-box-input {
            font-size: 16px;
            font-weight: 400;
            font-family: 'Poppins', sans-serif;
            color: black;
            outline: none;
            background: none;
            width: calc(550px - 60px);
          }

          ::placeholder {
            color: #919BB1;
          }

          .search-box-clear {
            display: flex;
            cursor: pointer;

            svg {
              width: 16px;
              height: auto;
            }
          }
        }
      }

      .navbar-links {
        display: flex;
        align-items: center;
        min-width: 0;

        svg {
          padding-top: 2px;
          cursor: pointer;
        }

        svg:hover {
          path {
            stroke: #646464;
          }

          rect {
            fill: #646464;
          }
        }

        a, .login, .logout {
          font-size: 15px;
          font-weight: 400;
          color: black;
          letter-spacing: -0.28px;
          text-decoration: none;
          padding-left: 30px;
          cursor: pointer;
        }

        a:hover {
          color: #646464;
        }

        .create-account {
          margin-left: 30px;
          font-weight: 500;
          color: #EC3E26;
          cursor: pointer;
        }

        .create-account:hover {
          color: #FF6C5C;
        }

        .navbar-profile {
          .navbar-profile-content {
            width: 30px;
            height: 30px;
            border-radius: 100px;
            overflow: hidden;

            .navbar-profile-picture {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    // Styling for mobile navbar links
    .mobile-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;

      .hamburger-icon {
        svg {
          display: block;
        }
      }

      .sanctuary-logo {
        width: 110px;
        height: 100%;

        svg {
          width: 110px;
          height: 100%;
        }
      }

      svg {
        cursor: pointer;
      }
    }
  }
}

.navbar.active {
  box-shadow: 0px 1px 10px rgba(0,0,0,0.1);
}
