.newsroom-article {
  background: white;
  // padding-bottom: 166px;

  .newsroom-article-content {
    max-width: 1000px;
    margin: 0px auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 75px;

    .newsroom-article-header {
      max-width: 680px;
      margin: 0px auto;
      padding-top: 145px;
      padding-bottom: 30px;

      h1 {
        font-size: 48px;
        font-weight: 600;
        color: black;
        line-height: 52px;
        letter-spacing: -1px;
      }

      .newsroom-article-profile {
        display: flex;
        padding-top: 30px;

        img {
          width: 48px;
          height: 48px;
          border-radius: 1000px;
        }

        .newsroom-article-date {
          padding-top: 3px;
          padding-left: 12px;

          h2 {
            font-size: 15px;
            font-weight: 600;
            color: black;
          }

          p {
            font-size: 13px;
            font-weight: 400;
            color: #6E6E72;
          }
        }
      }
    }

    .newsroom-article-image {
      padding-top: 20px;
      padding-bottom: 50px;

      img {
        display: block;
        max-width: 1000px;
        min-width: 680px;
        margin: 0px auto;
        border-radius: 18px;
      }

      p {
        font-size: 11px;
        font-weight: 300;
        color: #6E6E72;
        max-width: 680px;
        margin: 0px auto;
        padding-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .newsroom-article-text {
      max-width: 680px;
      margin: 0px auto;
      padding: 0px 20px;

      p {
        font-size: 20px;
        font-weight: 300;
        color: black;
        line-height: 32px;
        letter-spacing: -0.1px;
        margin-bottom: 30px;
      }

      h4 {
        font-size: 22px;
        font-weight: 600;
        color: black;
        margin-bottom: 10px;
      }

      a {
        color: #2767C5;
        text-decoration: none;
      }
    }
  }
}

@media (min-width: 740px) and (max-width: 1069px) {
  .newsroom-article {
    .newsroom-article-content {
      max-width: 690px;

      .newsroom-article-header {
        max-width: 570px;
        padding-top: 130px;

        h1 {
          font-size: 38px;
          line-height: 57px;
        }

        .newsroom-article-profile {
          padding-top: 20px;
  
          img {
            width: 42px;
            height: 42px;
            border-radius: 1000px;
          }
  
          .newsroom-article-date {
            padding-top: 2px;
            padding-left: 12px;
  
            h2 {
              font-size: 13px;  
            }
  
            p {
              font-size: 11px;
            }
          }
        }
      }

      .newsroom-article-image {
        padding-top: 20px;
        padding-bottom: 40px;

        img {
          max-width: 690px;
          min-width: 570px;
        }

        p {
          font-size: 10px;
          max-width: 570px;
        }
      }

      .newsroom-article-text {
        max-width: 570px;
  
        p {
          font-size: 18px;
          line-height: 29px;
          margin-bottom: 20px;
        }
  
        h4 {
          font-size: 20px;
          font-weight: 600;
          color: black;
          margin-bottom: 10px;
        }
  
        a {
          color: #2767C5;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: 739px) {
  .newsroom-article {
    .newsroom-article-content {
      max-width: 410px;
      min-width: 327px;
      padding-left: 24px;
      padding-right: 24px;

      .newsroom-article-header {
        max-width: 327px;
        padding-top: 75px;
        padding-bottom: 15px;

        h1 {
          font-size: 32px;
          line-height: 42px;
          letter-spacing: -0.1px;
        }

        .newsroom-article-profile {
          padding-top: 25px;
  
          img {
            width: 40px;
            height: 40px;
            border-radius: 1000px;
          }
  
          .newsroom-article-date {
            padding-top: 2px;
            padding-left: 12px;
  
            h2 {
              font-size: 13px;  
            }
  
            p {
              font-size: 11px;
            }
          }
        }
      }

      .newsroom-article-image {
        padding-top: 15px;
        padding-bottom: 30px;

        img {
          max-width: 410px;
          min-width: 327px;
          width: 100%;
          border-radius: 15px;
        }

        p {
          font-size: 9px;
          max-width: 360px;
          padding-left: 0px;
          padding-right: 0px;
        }
      }

      .newsroom-article-text {
        max-width: 360px;
        padding: 0px;
  
        p {
          font-size: 17px;
          line-height: 27px;
          margin-bottom: 15px;
        }
  
        h4 {
          font-size: 19px;
          font-weight: 600;
          color: black;
          margin-bottom: 6px;
        }
  
        a {
          color: #2767C5;
          text-decoration: none;
        }
      }
    }
  }
}










