.home-item-card {
  width: 241px;
  min-width: 220px;
  height: 300px;
  background: white;
  border-radius: 16px;
  cursor: pointer;
  margin: 0 1px;
  border: 1px solid black;
  
  .home-item-card-content {
    padding: 8px;

    .home-item-card-sneaker {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      height: 149px;
      overflow: hidden;

      img {
        width: 175px;
        height: auto;
      }
    }

    .home-item-card-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 114px;
      padding-top: 22px;

      h2 {
        font-size: 16.5px;
        font-weight: 600;
        line-height: 25px;
        color: black;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .home-item-card-estimated-price {
        display: flex;
        .item-card-estimated {
          color: #777F93;
          font-weight: 500;
        }

        .home-item-card-price {
          margin-left: 5px;
          color: black;
          font-weight: 600;
        }
      }
    }
  }
}

@media (min-width: 700px) and (max-width: 819px) {
  .home-item-card {
    width: 200px;
    height: 260px;
    border-radius: 15px;

    .home-item-card-content {
      padding: 10px;

      .home-item-card-sneaker {
        height: 110px;

        img {
          width: 145px;
        }
      }

      .home-item-card-text {
        height: 116px;
        padding-top: 20px;

        h2 {
          font-size: 15.5px;
          line-height: 22px;
        }
      }
    }
  }
}


@media screen and (max-width: 699px) {
  .home-item-card {
    width: 162px;
    height: 260px;
    border-radius: 13px;

    .home-item-card-content {
      padding: 10px;

      .home-item-card-sneaker {
        height: 103px;

        img {
          width: 120px;
        }
      }

      .home-item-card-text {
        height: 110px;
        padding-top: 20px;

        h2 {
          font-size: 15.5px;
          line-height: 22px;
        }
      }
    }
  }
}