.next-articles {
  background: #F7F9FC;

  .next-articles-content {
    max-width: 1000px;
    margin: 0px auto;
    padding: 75px 20px;

    h2 {
      font-size: 28px;
      font-weight: 600;
      color: black;
    }

    .next-articles-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 27.5px;
      padding-top: 25px;
    }
  }
}

@media (min-width: 740px) and (max-width: 1069px) {
  .next-articles {
    .next-articles-content {
      max-width: 690px;

      h2 {
        font-size: 24px;
      }

      .next-articles-cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
        padding-top: 15px;
      }
    }
  }
}

@media screen and (max-width: 739px) {
  .next-articles {
    .next-articles-content {
      max-width: 690px;

      h2 {
        font-size: 24px;
      }

      .next-articles-cards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 25px;
        padding-top: 15px;
      }
    }
  }
}