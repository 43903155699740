.brand-card {
  display: flex;
  align-items: center;
  height: 55px;
  background-color: #F5F5F7;
  border-radius: 100px;
  margin-left: 20px;
  cursor: pointer;

  .brand-card-content {
    p {
      font-size: 18px;
      font-weight: 400;
      color: black;
      line-height: 27px;
      letter-spacing: -0.28px;
      white-space: nowrap;
      padding: 0px 30px;
    }
  }
}

.brand-card.start {
  margin-left: 0px;
}

.brand-card:hover {
  background-color: #ECECF0;
}

@media (min-width: 900px) and (max-width: 1199px) {
  .brand-card {
    height: 50px;
    margin-left: 15px;

    .brand-card-content {
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.1px;
        padding: 0px 26px;
      }
    }
  }
}

@media (min-width: 770px) and (max-width: 899px) {
  .brand-card {
    height: 44px;
    margin-left: 10px;

    .brand-card-content {
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.1px;
        padding: 0px 24px;
      }
    }
  }

  .brand-card.start {
    margin-left: 20px;
  }

  .brand-card.end {
    margin-right: 20px;
  }
}

@media (min-width: 700px) and (max-width: 769px) {
  .brand-card {
    height: 44px;
    margin-left: 10px;

    .brand-card-content {
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.1px;
        padding: 0px 24px;
      }
    }
  }

  .brand-card.start {
    margin-left: calc((100vw - 550px) / 2);
  }

  .brand-card.end {
    margin-right: 20px;
  }
}

@media screen and (max-width: 699px) {
  .brand-card {
    height: 44px;
    margin-left: 10px;

    .brand-card-content {
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.1px;
        padding: 0px 24px;
      }
    }
  }

  .brand-card.start {
    margin-left: calc((100vw - 335px) / 2);
  }

  .brand-card.end {
    margin-right: 20px;
  }
}
