.edit-profile-page {

  .edit-profile-page-container {
    padding: 0px 24px;
    padding-bottom: 50px;
    height: 500px;

    .edit-profile-page-title {
      // border-bottom: 1px solid #D9D9D9;
      max-width: 380px;
      margin: 0px auto;

      h1 {
        margin-top: 15px;
        color: black;
        font-size: 30px;
        font-weight: 600;
      }

      p {
        margin-top: 10px;
        margin-bottom: 60px;
        font-size: 15px;
        font-weight: 400;
        color: #6E6E72;
      }
    }

    .edit-profile-page-content {
      .edit-profile-page-error {
        font-size: 15px;
        font-weight: 400;
        color: red;
        max-width: 380px;
        margin: 0px auto;
      }

      .edit-profile-page-input {
        margin-top: 0px;

        .edit-input {
          height: 60px;
          max-width: 380px;
          margin: 0px auto;
          display: flex;
          align-items: center;
          border-bottom: 0.5px solid #cacaca;

          .inputBox {
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 300;
            color: black;
            width: 100%;
            outline: none;
            background: none;
          }
        }
      }

      .edit-profile-page-buttons {
        margin-top: 40px;

        .save-edit {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 380px;
          margin: 0px auto;
          height: 50px;
          border-radius: 14px;
          background: linear-gradient(45deg, #F14F8E, #EF6660);
          font-size: 16px;
          font-weight: 400;
          color: white;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (min-width: 930px) {
  .edit-profile-page {
    .edit-profile-page-container {
      padding-bottom: 100px;

      .edit-profile-page-title {
        margin-top: 80px;
      }
    }
  }
}
