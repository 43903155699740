.delete-confirmation {
  width: calc(100% - 48px);
  max-width: 380px;
  min-width: 272px;
  height: 350px;
  background: #F5F5F7;
  margin: auto;
  border-radius: 30px;
  text-align: center;

  .delete-confirmation-container {
    padding: 30px;
  
    h1 {
      font-size: 30px;
      font-weight: 600;
      color: black;
      letter-spacing: 0.3px;
    }

    .delete-middle-text {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 400;
      color: #6E6E72;
      height: 120px;
    }

    .delete-buttons {
      .cancel-delete-button, .confirm-delete-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 45px;
        border-radius: 12px;
        background: #E8E8EA;
        margin-top: 10px;
        cursor: pointer;

        p {
          font-size: 13px;
          font-weight: 400;
        }
      }

      .confirm-delete-button {
        background: linear-gradient(45deg, #F14F8E, #EF6660);
        color: white;
      }
      
    }
  }
}