.brand-cards {
  margin-bottom: 30px !important;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  flex-shrink: 0;
  margin: 0 -1px;
}

.carousels {
  background: #F7F9FC;;
}
