.carousel-card {
  margin-left: 15px;
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: white;
  border-radius: 18px;
  cursor: pointer;
}
.carousel-card.start {
  margin-left: 8vw;
}
.carousel-card.end {
  margin-right: 8vw;
}

.carousel-card-trending {
  display: flex;
  width: 1000px;
  height: 375px;
  border: 1px solid #E5E9F1;
  border-radius: 18px;
  box-shadow: 4px 4px 20px 5px rgba(0,0,0,0.02);

  .carousel-card-trending-sneaker {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;

    img {
      width: 275px;
    }
  }

  .carousel-card-trending-text {
    width: 50%;
    margin-top: 90px;

    .carousel-card-trending-model {
      width: 330px;
      margin-left: 55px;
      margin-bottom: 35px;

      p {
        font-size: 16px;
        font-weight: 500;
        color: #EC3E26;
        line-height: 24px;
      }

      h2 {
        font-size: 24px;
        font-weight: 500;
        color: black;
        line-height: 33px;
        height: 66px;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .carousel-card-trending-price {
      width: 330px;
      margin-left: 55px;

      p {
        font-size: 18px;
        font-weight: 500;
        color: #777F93;
        line-height: 27px;
      }

      h4 {
        font-size: 36px;
        font-weight: 600;
        color: black;
        line-height: 54px;
      }
    }
  }
}

.carousel-card-deals {
  border-radius: 18px;
  //box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.04);

  .carousel-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 215px; //335px;
    height: 320px; //434px;

    .carousel-card-image {
      margin-bottom: 0px;

      img {
        width: 200px;
      }
    }

    .carousel-card-text {
      width: 215px;
      margin-bottom: 16px;
      margin-left: 4px;

      h2 {
        font-size: 18px;
        font-weight: 500;
        color: black;
        line-height: 25px;
        height: 50px;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .carousel-card-price {
      width: 215px;
      margin-bottom: 20px;
      text-align: center;

      p {
        font-size: 16px;
        font-weight: 500;
        color: #777F93;
        line-height: 24px;
      }

      h4 {
        font-size: 24px;
        font-weight: 600;
        color: black;
        line-height: 36px;
      }
    }
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  .carousel-card {
    border-radius: 12px;
  }

  .carousel-card-trending {
    width: 800px;
    height: 325px;

    .carousel-card-trending-sneaker {
      img {
        width: 215px;
      }
    }

    .carousel-card-trending-text {
      margin-top: 95px;
  
      .carousel-card-trending-model {
        width: 265px;
        margin-left: 45px;
        margin-bottom: 26px;
  
        p {
          font-size: 14px;
          line-height: 21px;
        }

        h2 {
          font-size: 18px;
          line-height: 25px;
          height: 50px;
        }
      }
  
      .carousel-card-trending-price {
        width: 265px;
        margin-left: 45px;
  
        p {
          font-size: 16px;
          line-height: 24px;
        }

        h4 {
          font-size: 24px;
          line-height: 36px;
        }
      }
    }
  }
}

@media (min-width: 700px) and (max-width: 899px) {
  .carousel-card {
    border-radius: 12px;
    margin-left: 10px;
    margin-top: 15px;
  }

  .carousel-card-trending {
    width: 550px;
    height: 275px;

    .carousel-card-trending-sneaker {
      img {
        width: 165px;
      }
    }

    .carousel-card-trending-text {
      margin-top: 80px;
  
      .carousel-card-trending-model {
        width: 215px;
        margin-left: 25px;
        margin-bottom: 22px;
  
        p {
          font-size: 12px;
          line-height: 18px;
        }

        h2 {
          font-size: 15px;
          line-height: 21px;
          height: 42px;
        }
      }
  
      .carousel-card-trending-price {
        width: 215px;
        margin-left: 25px;
  
        p {
          font-size: 14px;
          line-height: 21px;
        }
  
        h4 {
          font-size: 21px;
          line-height: 32px;
        }
      }
    }
  }

  .carousel-card-deals {
    .carousel-card-content {
      width: 245px;
      height: 335px;
  
      .carousel-card-image {
        margin-bottom: 21px;
  
        img {
          display: block;
          width: 175px;
        }
      }
  
      .carousel-card-text {
        width: 195px;
        margin-bottom: 22px;
  
        h2 {
          font-size: 15px;
          line-height: 21px;
          height: 42px;
        }
      }
  
      .carousel-card-price {
        width: 195px;
        margin-bottom: 28px;
  
        p {
          font-size: 14px;
          line-height: 21px;
        }
  
        h4 {
          font-size: 21px;
          line-height: 32px;
        }
      }
    }
  }
}

@media screen and (max-width: 699px) {
  .carousel-card {
    border-radius: 10px;
    margin-left: 10px;
    margin-top: 15px;
  }

  .carousel-card-trending {
    flex-direction: column;
    width: 335px;
    height: 455px;

    .carousel-card-trending-sneaker {
      align-items: flex-end;
      width: 100%;
      height: 218px;
      margin-top: 35px;

      img {
        width: 215px;
      }
    }

    .carousel-card-trending-text {
      width: 100%;
      margin-top: 0px;

      .carousel-card-trending-model {
        width: 265px;
        margin: 0px auto;
        margin-bottom: 32px;

        p {
          font-size: 14px;
          line-height: 21px;
        }

        h2 {
          font-size: 18px;
          line-height: 25px;
          height: 50px;
        }
      }

      .carousel-card-trending-price {
        width: 265px;
        margin: 0px auto;
        margin-bottom: 40px;

        p {
          font-size: 16px;
          line-height: 24px;
        }

        h4 {
          font-size: 24px;
          line-height: 36px;
        }
      }
    }
  }

  .carousel-card-deals {
    .carousel-card-content {
      width: 245px;
      height: 335px;
  
      .carousel-card-image {
        margin-bottom: 21px;
  
        img {
          display: block;
          width: 175px;
        }
      }
  
      .carousel-card-text {
        width: 195px;
        margin-bottom: 22px;
  
        h2 {
          font-size: 15px;
          line-height: 21px;
          height: 42px;
        }
      }
  
      .carousel-card-price {
        width: 195px;
        margin-bottom: 28px;
  
        p {
          font-size: 14px;
          line-height: 21px;
        }
  
        h4 {
          font-size: 21px;
          line-height: 32px;
        }
      }
    }
  }
}
