.portfolio-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.6);
  animation: fade-in-out 0.3s;

  .portfolio-modal-close {
    display: flex;
    justify-content: flex-end;
    margin: 8px 16px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 100px;
      background: #EEF1F6;
      cursor: pointer;
    }

    button:hover {
      background: #E5E9F1;
    }
  }

  .portfolio-modal-content {
    width: 100%;
    height: calc(100vh - 44px);
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    background: white;
    overflow-y: auto;
    overflow-x: auto;
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }
}