.item-no-results {
  width: 100%;
  height: 150px;

  .item-no-results-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-right: 20px;

    p {
      font-size: 20.5px;
      font-weight: 400;
      color: #919BB1;
    }
  }
}

@media screen and (max-width: 767px) {
  .item-no-results {
    height: 120px;

    .item-no-results-content {
      padding-right: 15px;

      p {
        font-size: 16px;
      }
    }
  }
}