.currency-option {
  width: 100%;
  height: 60px;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid #E5E9F1;

  .currency-option-content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    .currency-option-text {
      display: flex;
      align-items: center;
      padding-left: 35px;

      img {
        width: 30px;
        height: 30px;
        border-radius: 100px;
        box-sizing: border-box;
        border: 1px solid #E5E9F1; 
      }

      p {
        font-size: 16px;
        font-weight: 400;
        height: 24px;
        padding-left: 15px;
      }
    }
  }
}

.currency-option {
  background: white;

  .currency-option-text {
    p {
      color: black;
    }
  }
}

.currency-option:hover {
  background: #EEF1F6;
}

.currency-option.current {
  border: none;
  background: #EC3E26;

  .currency-option-text.current {
    padding-left: 36px;

    p {
      color: white;
    }
  }
}

@media screen and (max-width: 767px) {
  .currency-option {
    border-radius: 8px;

    .currency-option-content {
      .currency-option-text {
        padding-left: 15px;

        img {
          width: 20px;
          height: 20px;
        }

        p {
          padding-left: 10px;
        }
      }
    }
  }

  .currency-option.current {
    .currency-option-text.current {
      padding-left: 16px;
    }
  }
}
