.item-card {
  width: 241px;
  height: 356px;
  background: white;
  border-radius: 12px;
  cursor: pointer;
  
  .item-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    .item-card-sneaker {
      margin-bottom: 21px;

      img {
        display: block;
        width: 175px;
      }
    }

    .item-card-text {
      width: 191px;

      h2 {
        font-size: 15px;
        font-weight: 500;
        line-height: 21px;
        color: black;
        height: 63px;
        margin-bottom: 22px;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .item-card-estimated-price {
        margin-bottom: 28px;

        .item-card-estimated {
          font-size: 14px;
          font-weight: 500;
          color: #777F93;
          line-height: 21px;
          letter-spacing: -0.28px;
        }

        .item-card-price {
          font-size: 21px;
          font-weight: 600;
          color: black;
          line-height: 32px;
          letter-spacing: -0.28px;
        }
      }
    }
  }
}

@media (min-width: 700px) and (max-width: 819px) {
  .item-card {
    width: 200px;
    height: 290px;
    border-radius: 10px;

    .item-card-content {
      .item-card-sneaker {
        margin-bottom: 17px;

        img {
          width: 143px;
        }
      }

      .item-card-text {
        width: 150px;

        h2 {
          font-size: 13px;
          line-height: 17px;
          height: 51px;
          margin-bottom: 17px;
        }

        .item-card-estimated-price {
          margin-bottom: 22px;

          .item-card-estimated {
            font-size: 12px;
            line-height: 17px;
          }
  
          .item-card-price {
            font-size: 17px;
            line-height: 26px;
          }
        }
      }
    } 
  }
}


@media screen and (max-width: 699px) {
  .item-card {
    width: 162px;
    height: 280px;
    border-radius: 10px;

    .item-card-content {
      .item-card-sneaker {
        margin-bottom: 19px;

        img {
          width: 120px;
        }
      }

      .item-card-text {
        width: 125px;

        h2 {
          font-size: 13px;
          line-height: 17px;
          height: 51px;
          margin-bottom: 21px;
        }

        .item-card-estimated-price {
          margin-bottom: 25px;

          .item-card-estimated {
            font-size: 12px;
            line-height: 18px;
          }
  
          .item-card-price {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}