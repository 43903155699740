.email-form {
  padding-top: 26px;

  .email-form-content {
    padding: 0px 20px;
    padding-bottom: 100px;
  }

  .email-form-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;

    img {
      height: 44px;
    }

    h2 {
      font-size: 31px;
      font-weight: 600;
      color: black;
      margin-bottom: 12px;
    }

    p {
      font-size: 14px;
      font-weight: 300;
      color: #868686;
    }
  }

  .email-form-success {
    font-size: 14px;
    font-weight: 300;
    color: black;
    max-width: 380px;
    margin: 0px auto;
  }

  .email-form-error {
    font-size: 14px;
    font-weight: 300;
    color: red;
    max-width: 380px;
    margin: 0px auto;
  }

  .email-form-input, .email-form-input-create-account {
    margin-bottom: 20px;

    .email-input, .name-input, .password-input, .confirm-password-input {
      height: 60px;
      max-width: 380px;
      margin: 0px auto;
      display: flex;
      align-items: center;
      border-bottom: 0.5px solid #cacaca;

      .inputBox {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 300;
        color: black;
        width: 100%;
        outline: none;
        background: none;
      }

      ::placeholder {
        color: #afafaf;
      }
    }

    .forgot-password {
      max-width: 380px;
      margin: 0px auto;
      padding-top: 10px;

      .forgot-password-text {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #3478F6;
        text-decoration: none;
      }

      .forgot-password-text:hover {
        text-decoration: underline;
      }
    }
  }

  .email-form-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
      max-width: 380px;
      height: 40px;
      border-radius: 99em;
      background: linear-gradient(45deg, #F14F8E, #EF6660);
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: white;
      margin-bottom: 4px;
      cursor: pointer;
      outline: none;
    }

    p {
      font-size: 12px;
      font-weight: 300;
      color: #868686;
      letter-spacing: 0.4px;
    }
  }
}

@media screen and (min-width: 930px) {
  .email-form {
    .email-form-content {
      padding-bottom: 166px;
    }

    .email-form-input {
      margin-bottom: 20px !important;
    }
  }
  .email-form-error {
    margin: 0px auto;
  }
}