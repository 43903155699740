.portfolio-card {
    cursor: pointer;
    margin-left: 15px;
    border-bottom: 1px solid #EEF1F6;

    .portfolio-card-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 15px;
        text-decoration: none;

        .portfolio-card-info {
            display: flex;
            align-items: center;
            margin: 18px 0px;

            .portfolio-card-sneaker {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
                border-radius: 999px;
                box-shadow: inset 0 0 1px #848489;

                img {
                    width: 35px;
                    height: auto;
                    margin: 7.5px;
                }
            }

            .portfolio-card-model {
                margin-left: 18px;

                h2 {
                    font-size: 18px;
                    font-weight: 600;
                    color: black;
                    max-width: 500px;
                    
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                p {
                    font-size: 15px;
                    font-weight: 400;
                    color: #8A8A8D;
                    margin-top: 1px;
                }
            }
        }

        .portfolio-card-price {
            display: flex;

            .portfolio-card-price-data {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                height: 79px;
                margin-right: 12px;

                h2 {
                    font-size: 18px;
                    font-weight: 400;
                    color: black;
                }

                h4 {
                    font-size: 15px;
                    font-weight: 400;
                    margin-top: 1px;
                }
            }

            svg {
                margin-top: 5px;
            }
        }
    }
}

// Mobile responsiveness
@media screen and (max-width: 767px) {
    .portfolio-card {
        cursor: none;
        margin-left: 13px;
    
        .portfolio-card-content {
            padding-right: 12px;
    
            .portfolio-card-info {
                margin: 10px 0px;
    
                .portfolio-card-sneaker {
                    width: 36px;
                    height: 36px;
    
                    img {
                        width: 20px;
                        margin: 8px;
                    }
                }
    
                .portfolio-card-model {    
                    h2 {
                        font-size: 16px;
                        max-width: 400px;
                    }
    
                    p {
                        font-size: 14.5px;
                    }
                }
            }
    
            .portfolio-card-price {
                margin-left: 48px;

                .portfolio-card-price-data {
                    height: 69px;
                    margin-right: 17px;
    
                    h2 {
                        font-size: 16px;
                    }
    
                    h4 {
                        font-size: 13px;
                    }
                }
    
                svg {
                    margin-top: 4px;
                }
            }
        }
    }
}
