/* Web */

.about::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.about {
  width: 910px;
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */

  .close-area {
    height: 130px;
    width: 910px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .cardOne {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 1185px;
    width: 910px;
    background: white;
    border-radius: 20px;
    margin-top: 10px;

    .cardOne-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      height: 905px;
      width: 658px;
      text-align: center;

      .cardOne-title {

        h1 {
          font-size: 64px;
          font-weight: 600;
          color: #353234;
        }
  
        h2 {
          font-size: 32px;
          font-weight: 500;
          color: #353234;
          margin-top: 15px;
        }
      }

      img {
        width: 100%;
      }

      p {
        font-size: 18px;
        font-weight: 500;
        color: #353234;
      }
    }
  }

  .cardTwo {
    height: 1185px;
    width: 910px;
    background: white;
    border-radius: 20px;
    margin-top: 50px;

    .cardTwo-top {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      height: 310px;
      text-align: center;

      h1 {
        font-size: 64px;
        font-weight: 600;
        color: #353234;
      }

      h2 {
        font-size: 36px;
        font-weight: 500;
        color: #353234;
        margin-top: 5px;
        margin-bottom: 30px;
      }
    }

    .cardTwo-bottom {
      display: flex;
      height: 875px;
      
      .leftText {
        width: 455px;

        h3 {
          font-size: 26px;
          font-weight: 600;
          color: #353234;
          margin: 0;
          margin-top: 50px;
          margin-bottom: 11px;
          padding-left: 126px;
        }

        p {
          font-size: 18px;
          font-weight: 500;
          color: #353234;
          padding-left: 126px;
        }
      }

      .rightImage {
        width: 455px;

        img {
          width: 425px;
          margin-top: 50px;
          margin-left: 30px;
        }
      }
    }
  }

  .cardThree {
    display: flex;
    justify-content: center;

    height: 1185px;
    background: white;
    border-radius: 20px;
    margin-top: 50px;

    .cardThree-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 658px;
      height: 1185px;
      text-align: center;

      h1 {
        font-size: 64px;
        font-weight: 600;
        line-height: 85px;
        color: #353234;
        padding-top: 140px;
      }

      p {
        font-size: 18px;
        font-weight: 500;
        color: #353234;
      }

      img {
        width: 100%;
      }
    }
  }

  .cardFour {
    display: flex;
    align-items: center;
    justify-content: center;

    .cardFour-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 910px;

      h1 {
        font-size: 64px;
        font-weight: 600;
        color: white;
      }

      h2 {
        font-size: 18px;
        font-weight: 500;
        color: white;
        padding: 0px 255px;
        margin-bottom: 32px;
        margin-top: 20px;
        
      }

      a.contact-box {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 55px;
        width: 371px;
        border-radius: 100px;
        background: #3478F6;
        transition: 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);

        font-family: 'Poppins', sans-serif;
        font-size: 13px;
        font-weight: 500;
        color: white;
        text-decoration: none;
        outline: none;
      }

      a.contact-box:hover {
        background: #2D72DB;
      }
    }
  }
}

/* Tablet */

@media (max-width: 1068px) and (min-width: 735px) {

  .about {
    width: 637px;

    .close-area {
      width: 637px;
    }

    .cardOne {
      height: 930px;
      width: 637px;

      .cardOne-content {
        height: 718px;
        width: 465px;

        .cardOne-title {

          h1 {
            font-size: 45px;
            line-height: 68px;
          }

          h2 {
            font-size: 24px;
            font-weight: 400;
            line-height: 36px;
            margin-top: 0px;
          }
        }

        p {
          font-size: 16px;
        }
      }
    }

    .cardTwo {
      height: 930px;
      width: 637px;

      .cardTwo-top {
        height: 239px;

        h1 {
          font-size: 45px;
          // line-height: 68px;
        }

        h2 {
          font-size: 24px;
          // line-height: 45px;
          margin-bottom: 28px;
          margin-top: 0px;
        }
      }

      .cardTwo-bottom {
        height: 683px;

        .leftText {
          width: 325px;

          h3 {
            font-size: 20px;
            margin-top: 30px;
            margin-bottom: 5px;
            padding-left: 86px;
          }

          p {
            font-size: 16px;
            padding-left: 86px;
          }
        }

        .rightImage {
          width: 312px;
          
          img {
            width: 292px;
            margin-top: 110px;
            margin-left: 20px;
          }
        }
      }
    }

    .cardThree {
      height: 930px;
      width: 637px;

      .cardThree-content {
        width: 465px;
        height: 930px;

        h1 {
          font-size: 45px;
          line-height: 68px;
          padding-top: 106px;
        }

        p {
          font-size: 16px;
        }
      }
    }

    .cardFour {

      .cardFour-content {
        width: 637px;

        h1 {
          font-size: 48px;
        }

        h2 {
          font-size: 16px;
          padding: 0px 143.5px;
          margin-top: 16px;
          margin-bottom: 28px;
        }

        a.contact-box {
          height: 50px;
          width: 330px;
          font-size: 12px;
        }
      }
    }
  }
}

/* Mobile */

@media screen and (max-width: 734px) {

  .about {
    width: 355px;

    .close-area {
      height: 92px;
      width: 355px;
    }

    .cardOne {
      height: 659px;
      width: 355px;
      margin-top: 8px;

      .cardOne-content {
        width: 260px;
        height: 539px;

        .cardOne-title {

          h1 {
            font-size: 48px;
            line-height: 53px;
          }

          h2 {
            font-size: 24px;
            font-weight: 400;
            line-height: 26px;
            margin-top: 10px;
          }
        }

        p {
          font-size: 11px;
        }
      }
    }

    .cardTwo {
      height: 659px;
      width: 355px;
      margin-top: 40px;

      .cardTwo-top {
        height: 150px;

        h1 {
          font-size: 37px;
          line-height: 40px;
        }

        h2 {
          font-size: 24px;
          font-weight: 400;
          margin-bottom: 18px;
          margin-top: 0px;
        }
      }

      .cardTwo-bottom {
        height: 509px;

        .leftText {
          width: 193px;

          h3 {
            font-size: 13px;
            margin-top: 20px;
            margin-bottom: 5px;
            padding-left: 53px;
          }

          p {
            font-size: 11px;
            padding-left: 53px;
          }
        }

        .rightImage {
          width: 162px;
          
          img {
            width: 152px;
            margin-top: 110px;
            margin-left: 10px;
          }
        }
      }
    }

    .cardThree {
      height: 659px;
      width: 355px;
      margin-top: 40px;

      .cardThree-content {
        width: 260px;
        height: 659px;

        h1 {
          font-size: 35px;
          line-height: 48px;
          padding-top: 50px;
        }

        p {
          font-size: 11px;
        }
      }
    }

    .cardFour {

      .cardFour-content {
        width: 355px;

        h1 {
          font-size: 50px;
        }

        h2 {
          font-size: 11px;
          padding: 0px 47.5px;
          margin-top: 16px;
          margin-bottom: 28px;
        }

        a.contact-box {
          height: 45px;
          width: 245.22px;
          font-size: 10.5px;
        }
      }
    }
  }
}