.how-it-works-contact {
  .how-it-works-contact-content {
    padding: 166px 0px;
  }
}

@media (min-width: 600px) and (max-width: 849px) {
  .how-it-works-contact {
    .how-it-works-contact-content {
      padding: 100px 0px;
    }
  }
}

@media screen and (max-width: 599px) {
  .how-it-works-contact {
    .how-it-works-contact-content {
      padding: 100px 0px;
    }
  }
}