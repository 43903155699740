.article-card {
  width: 315px;
  height: 380px;
  background: white;
  border-radius: 18px;
  overflow: hidden;
  cursor: pointer;

  .article-card-image {
    width: 100%;
    height: 220px;
    background-position: center;
    background-size: cover;
  }

  .article-card-text {
    width: 260px;
    margin: 0px auto;
    padding-top: 24px;

    p {
      font-size: 13px;
      font-weight: 500;
      color: #6E6E72;
    }

    h6 {
      font-size: 18px;
      font-weight: 600;
      color: black;
      line-height: 24px;
      letter-spacing: -0.28px;
      padding-top: 10px;
    }
  }
}

@media (min-width: 740px) and (max-width: 1069px) {
  .article-card {
    width: 333px;
    height: 395px;

    .article-card-image {
      height: 228px;
    }

    .article-card-text {
      h6 {
        padding-top: 13px;
      }
    }
  }
}

@media screen and (max-width: 739px) {
  .article-card {
    width: 327px;
    height: 395px;

    .article-card-image {
      height: 228px;
    }

    .article-card-text {
      h6 {
        padding-top: 13px;
      }
    }
  }
}