@import "./variables";
@import "./navbar";
@import "./splash";
@import "./catalog";
@import "./searchBar";
@import "./footer";
@import "./aboutModal";
@import "./modal";
@import "./pageNotFound";
@import "./termsOfUse";
@import "./privacyPolicy";
@import "./loginCreate";
@import "./accountButtonForm";
@import "./profile";
@import "./newsroom";
@import "./newsroomArticle";
@import "./newsroom-drops";
@import "./deleteModal";
@import "./editProfile";
@import "./blogArticle";
@import "./loader";
@import "./readNext";
@import "./homeSplash";
@import "./homeTrending";
@import "./homeNewsroom";
@import "./homeAccount";
@import "./contactUs";
@import "./browse";
@import "./item";
@import "./itemPrice";
@import "./itemListing";
@import "./searchModal";
@import "./itemLoader";
@import "./itemCard";
@import "./sizeFilter";
@import "./currencyFilter";
@import "./locationPopup";
@import "./sizeModal";
@import "./sizeOption";
@import "./currencyModal";
@import "./currencyOption";
@import "./itemNoResults";
@import "./hamburgerModal";
@import "./filtersModal";
@import "./filtersButton";
@import "./sortModal";
@import "./sortButton";
@import "./sortOption";
@import "./brandsOption";
@import "./articleCard";
@import "./nextArticles";
@import "./brandCard";
@import "./homeBrandCarousel";
@import "./homeCarousel";
@import "./homeCarouselCard";
@import "./homeApp";
@import "./carousel";
@import "./carouselCard";
@import "./portfolio";
@import "./portfolioItem";
@import "./portfolioItemCard";
@import "./portfolioItemModal";
@import "./howItWorksSplash";
@import "./howItWorksInfo";
@import "./howItWorksSites";
@import "./howItWorksCards";
@import "./howItWorksContact";
@import "./contactForm";
@import "./browseFilterDropdown";
@import "./socialsModal";
@import "./dynamicList";
@import "./loadingPortfolio";
@import "./loadingAssets";

@import "swiper/swiper";
@import "swiper/components/navigation/navigation";

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: $text-color;
  background: #000000;
  margin: 0;

  // width: 100vw;
  width: auto;
  height: 100%;
  overflow-x: hidden;
}

html {
  scroll-behavior: auto;
}

.hidden-link {
  color: inherit;
  text-decoration: inherit;
  /* If you want to implement it in very old browser-versions */
  -webkit-user-select: none; /* Chrome/Safari */ 
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* The rule below is not implemented in browsers yet */
  -o-user-select: none;

  /* The rule below is implemented in most browsers by now */
  user-select: none;
}