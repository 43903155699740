// Mobile

.privacy-policy {
  max-width: 748px;
  margin: 0px auto;

  .privacy {
    margin: 0px 24px;
    padding: 90px 0px;

    .mainCard {
      .mainCard-content {
        .privacy-title {
          text-align: center;

          h1 {
            font-size: 35px;
            font-weight: 600;
            color: black;
            margin-bottom: 15px;
          }

          h4 {
            font-size: 16px;
            font-weight: 500;
            color: black;
            margin-bottom: 10px;
          }

          p {
            font-size: 16px;
            font-weight: 400;
            color: rgb(43, 43, 43);
          }
        }

        .big-text {
          margin-top: 60px;
          margin-bottom: 10px;

          h1 {
            font-size: 24px;
            font-weight: 600;
            color: black;
            line-height: 35px;
          }
        }

        h2 {
          font-size: 20px;
          font-weight: 600;
          color: black;
          margin-top: 25px;
          margin-bottom: 5px;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          color: rgb(43, 43, 43);
          line-height: 25px;
          padding: 10px 0px;
        }

        ul {
          margin-left: 18px;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        a {
          text-decoration: none;
          outline: none;
          color: #2D70C3;
        }

        h3 {
          font-size: 18px;
          font-weight: 600;
          color: black;
          margin-top: 25px;
          margin-bottom: 10px;
        }

        img {
          height: 20px;
        }
      }
    }
  }
}

// Desktop

@media screen and (min-width: 1000px) {
  .privacy-policy {
    .privacy {
      .mainCard {
        .mainCard-content {
          .privacy-title {
            h1 {
              font-size: 40px;
            }

            h4 {
              font-size: 18px;
            }

            p {
              font-size: 18px;
              padding: 12px 0px;
            }
          }

          .big-text {
            margin-top: 70px;
            padding-bottom: 5px;

            h1 {
              font-size: 26px;
            }
          }

          h2 {
            font-size: 22px;
            margin-top: 30px;
          }

          p {
            font-size: 18px;
            padding: 12px 0px;
          }

          h3 {
            font-size: 20px;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
