.carousel {
  .carousel-content {
    display: flex;
    overflow-x: auto;
  }
}

.carousel-content::-webkit-scrollbar {
  height: 8px;
}

.carousel-content::-webkit-scrollbar-thumb {
  background-color: #E5E9F1;
  border-radius: 100px;
}

.carousel-content::-webkit-scrollbar-track-piece:start {
  background-image: url("../images/scrollbar-track.png");
  background-repeat: repeat-x;
  background-size: contain;
  margin-left: 8vw;
}

.carousel-content::-webkit-scrollbar-track-piece:end {
  background-image: url("../images/scrollbar-track.png");
  background-repeat: repeat-x;
  background-size: contain;
  margin-right: 8vw; 
}

@media (min-width: 900px) and (max-width: 1199px) {
  .carousel-content::-webkit-scrollbar-track-piece:start {
    margin-left: calc((100vw - 800px) / 2);
  }
  
  .carousel-content::-webkit-scrollbar-track-piece:end {
    margin-right: calc((100vw - 800px) / 2); 
  }
}

@media (min-width: 770px) and (max-width: 899px) {
  .carousel-content::-webkit-scrollbar-track-piece:start {
    margin-left: calc((100vw - 550px) / 2);
  }
  
  .carousel-content::-webkit-scrollbar-track-piece:end {
    margin-right: calc((100vw - 550px) / 2); 
  }
}

@media screen and (max-width: 769px) {
  .carousel {
    .carousel-content {
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }

  .carousel-content::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }
}
