.modal-shoe, .modal-about, .modal-terms, .modal-privacy, .modal-delete, .modal-filter, .modal-location {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 100;

  backdrop-filter: blur(25px) brightness(0.85);
  -webkit-backdrop-filter: blur(20px) brightness(0.7); 

  animation: fade-in-out 0.3s;

  @supports not (backdrop-filter: blur(20px)) {
    background: rgba(29,29,31,0.97)
  } 
}

.modal-hamburger {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 100;
  background: rgba(0, 0, 0, 0.70)
}

.modal-shoe {
  // align-items: center;
  @supports not (backdrop-filter: blur(20px)) {
    background: rgba(#D6D6D6, 0.97);
  } 
}



.closeButton {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);

  svg {
    color: #353234;
    height: 20px;
    width: 20px;
  }
}

.closeButton:hover {
  background: #F3F3F3;
}

@keyframes fade-in-out {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}