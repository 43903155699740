.contact-form {
  background: white;

  .contact-form-content {
    max-width: 1000px;
    margin: auto;
    padding: 0px 20px;

    .contact-form-text {
      width: 700px;
      margin: auto;

      h1 {
        font-size: 68px;
        font-weight: 600;
        color: #292828;
        line-height: 80px;
        letter-spacing: -1.5px;
      }

      p {
        font-size: 20px;
        font-weight: 400;
        color: #292828;
        line-height: 30px;
        letter-spacing: -0.41px;
        margin-top: 15px;
        margin-bottom: 50px;
      }
    }

    .contact-form-boxes {
      display: flex;
      flex-direction: column;
      width: 700px;
      margin: auto;

      .contact-form-box-title {
        font-size: 13px;
        font-weight: 400;
        color: #292828;
        margin-bottom: 8px;
      }

      span {
        color: #EC3E26;
      }

      .contact-form-input {
        width: 410px;
        height: 52px;
        background: #F7F9FC;
        border-radius: 12px;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 300;
        color: #292828;
        margin-bottom: 40px;
        padding: 0px 20px;
      }

      .contact-form-input::placeholder {
        color: #AEB7CA;
      }

      .contact-form-input.message {
        width: 660px;
        height: 260px;
        padding: 20px 20px;
      }

      .contact-form-captcha {
        margin-bottom: 40px;
      }

      .contact-form-submit {
        width: 450px;
        height: 52px;
        background: #EC3E26;
        border-radius: 12px;
        cursor: pointer;

        p {
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          font-weight: 500;
          color: white;
        }

        svg {
          height: 35px;
        }
      }

      .contact-form-submit.true {
        cursor: default;
      }

      .contact-form-submit:hover {
        background: #D93822;
      }

      .contact-form-submit.true:hover {
        background: #EC3E26;
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 849px) {
  .contact-form {
    .contact-form-content {
      .contact-form-text {
        width: 500px;
        text-align: center;

        h1 {
          font-size: 50px;
          line-height: 55px;
          letter-spacing: -0.41px;
        }
  
        p {
          font-size: 20px;
          margin-bottom: 60px;
        }
      }

      .contact-form-boxes {
        width: 500px;
  
        .contact-form-input {
          width: 460px;
          height: 50px;
          margin-bottom: 30px;
        }
  
        .contact-form-input.message {
          width: 460px;
        }

        .contact-form-captcha {
          margin-bottom: 30px;
        }
  
        .contact-form-submit {
          width: 500px;
          height: 50px;

          svg {
            height: 33px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .contact-form {
    .contact-form-content {
      .contact-form-text {
        width: 335px;
        text-align: center;

        h1 {
          font-size: 42px;
          line-height: 46px;
          letter-spacing: -0.41px;
        }
  
        p {
          font-size: 18px;
        }
      }

      .contact-form-boxes {
        width: 335px;
  
        .contact-form-input {
          width: 295px;
          height: 45px;
          border-radius: 10px;
          margin-bottom: 30px;
        }
  
        .contact-form-input.message {
          width: 295px;
        }

        .contact-form-captcha {
          margin-bottom: 30px;
          margin-left: auto;
          margin-right: auto;
        }
  
        .contact-form-submit {
          width: 335px;
          height: 45px;
          border-radius: 10px;

          svg {
            height: 28px;
          }
        }
      }
    }
  }
}